import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { setQuickFetchErrorOnWindow } from './utils';
function quickFetchListingLibAppSettings() {
  getFrameworkDataQuickFetchClient().then(client => {
    client.graph.frameworkTypes.getObjects({
      appSettingNames: ['frameworkListingLib']
    });
  }).catch(error => {
    setQuickFetchErrorOnWindow(error);
  });
}
function quickFetchCrmObjectTypeDefinitions() {
  getFrameworkDataQuickFetchClient().then(client => {
    client.frameworkTypes.getObjects();
  }).catch(error => {
    setQuickFetchErrorOnWindow(error);
  });
}
function quickFetchPropertiesAndGroups(objectTypeId) {
  getFrameworkDataQuickFetchClient().then(client => {
    client.properties.getGroups({
      frameworkTypeIdentifier: objectTypeId
    });
  }).catch(error => {
    setQuickFetchErrorOnWindow(error);
  });
}
export function quickFetchListingLib(objectTypeId) {
  quickFetchListingLibAppSettings();
  quickFetchCrmObjectTypeDefinitions();
  quickFetchPropertiesAndGroups(objectTypeId);
}
export function quickFetchListingLibNoCrm() {
  quickFetchListingLibAppSettings();
}