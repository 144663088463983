import quickFetch from 'quick-fetch';
import { buildQueryString } from './utils';
const portalId = quickFetch.getPortalId();
export const CONTENT_OBJECT_TYPE_ID = '0-10';
export const REQUESTS = {
  domainsSettingsAndAttributesRequests: [{
    earlyRequestKey: 'initial-domains-fetch',
    url: 'cos-domains/v1/domains'
  }, {
    earlyRequestKey: 'initial-portal-settings-fetch',
    url: `content-settings/v1/site-settings/by-portal-id/${portalId}`
  }, {
    earlyRequestKey: 'initial-portal-scopes-fetch',
    url: `hubs2/v1/scopes/hub/${portalId}/filter?${buildQueryString({
      scopes: ['landingpages-access', 'sitepages-access', 'blog-access', 'landingpages-custom-domain-access', 'content-site-tree', 'website-pages-no-branding', 'landingpages-no-branding', 'blog-pages-no-branding', 'blog-posts-auto-translate', 'landingpages-auto-translate', 'sitepages-auto-translate']
    })}`
  }, {
    earlyRequestKey: 'initial-account-verification-status-fetch',
    url: 'vetting/public/v2/account-verification/state/scope/landingpages-trusted'
  }, {
    earlyRequestKey: 'themes-data-fetch',
    url: 'designmanager/v1/themes/list?templateTypeId=4'
  }],
  blogContentUsageRequests: [{
    earlyRequestKey: 'fetch-blogs-usage',
    url: `blog-settings/v1/blogs/${portalId}/usage`
  }, {
    earlyRequestKey: 'fetch-blog-posts-usage',
    url: `blogs/v3/blog-posts/${portalId}/usage`
  }],
  afterAuthNoScopeCheckRequests: [{
    earlyRequestKey: 'initial-user-settings-fetch',
    url: 'cms-user-settings'
  }]
};