import quickFetch from 'quick-fetch';
import { quickFetchViews } from 'framework-listing-lib/earlyRequester/viewsEarlyRequester';
import { quickFetchListingLib, quickFetchListingLibNoCrm } from 'framework-listing-lib/earlyRequester/listingLibEarlyRequester';
import { getNonViewsCustomColumnSort, getSearchOptions, getStartStateSearchOptions } from './viewUtils';
import { CONTENT_OBJECT_TYPE_ID, REQUESTS } from './requestConstants';
import { buildQueryString, getInitialBusinessUnitIdFilterValue, getMaybeCachedFirstViewRequestData, requestDispatcher } from './utils';
const BLOG_POSTS_URL = 'blogs/v3/blog-posts/crm-search/search';
const LANDING_PAGES_URL = 'cospages/v2/dashboard/landing-pages/crm-search/search';
const SITE_PAGES_URL = 'cospages/v2/dashboard/site-pages/crm-search/search';
const isLandingPagesView = window.location.pathname.indexOf('pages/landing') !== -1;
const isSitePagesView = window.location.pathname.indexOf('pages/site') !== -1;
const isBlogView = window.location.pathname.indexOf('/blog') !== -1;
const isBlogPostsView = window.location.pathname.indexOf('/blog/posts') !== -1 || /blog\/*$/.test(window.location.pathname);
const viewIdMatch = window.location.pathname.match(/^\/website\/\d+\/(pages\/(site|landing)|blog\/posts)\/((\d|\w)+)$/);
const currentNamespace = isLandingPagesView ? 'LANDING_PAGES' : isSitePagesView ? 'SITE_PAGES' : isBlogPostsView ? 'BLOG_POSTS' : undefined;
const viewId = viewIdMatch ? viewIdMatch[3] : viewIdMatch;
const contentTypeId = isBlogPostsView ? 3 : isLandingPagesView ? 1 : 4;
const sortedColumn = getNonViewsCustomColumnSort({
  isBlogPostsView,
  contentTypeId
});
REQUESTS.domainsSettingsAndAttributesRequests.forEach(requestDispatcher);
if (isBlogView) {
  REQUESTS.blogContentUsageRequests.forEach(requestDispatcher);
}
quickFetch.afterAuth(auth => {
  if (!auth) {
    return;
  }
  const portalId = auth.portal.portal_id;
  const gates = auth.portal.enabled_gates;
  const scopes = auth.user.scopes;
  let url;
  let startStateFetchKey;
  if (isBlogPostsView) {
    url = BLOG_POSTS_URL;
    startStateFetchKey = 'fetch-blog-posts-for-start-state';
  } else if (isLandingPagesView) {
    url = LANDING_PAGES_URL;
    startStateFetchKey = 'fetch-landing-pages-for-start-state';
  } else if (isSitePagesView) {
    url = SITE_PAGES_URL;
    startStateFetchKey = 'fetch-site-pages-for-start-state';
  }
  if (scopes.includes('crm-access') || scopes.includes('contacts-read')) {
    quickFetchListingLib(CONTENT_OBJECT_TYPE_ID);
  } else {
    quickFetchListingLibNoCrm();
  }
  REQUESTS.afterAuthNoScopeCheckRequests.forEach(requestDispatcher);
  if (url) {
    const startStateSearchOptions = getStartStateSearchOptions(auth);
    // Empty state fetch dispatched and used by framework-listing-lib and empty states outside of table context
    if (startStateFetchKey) {
      requestDispatcher({
        earlyRequestKey: startStateFetchKey,
        url,
        type: 'POST',
        data: JSON.stringify(startStateSearchOptions)
      });
    }
    let searchOptions;
    if (scopes.includes('content-management-saved-views') && scopes.includes('contacts-read')) {
      if (currentNamespace) {
        quickFetchViews({
          defaultViewIds: [-1, -2, -25, -26, -27, -33, -34, -35],
          namespace: currentNamespace,
          objectTypeId: '0-10'
        });
      }

      // Try to use cached request data from user's "first view tab" if no standard view present in URL
      const viewRequestData = getMaybeCachedFirstViewRequestData(contentTypeId, portalId);
      if (viewRequestData) {
        searchOptions = viewRequestData;
      } else if (!viewId || viewId === 'all') {
        // Optimistically assume that the view is the 'all' standard view if no viewId is present in URL and we have no cached data
        searchOptions = getSearchOptions({
          auth,
          viewId: 'all',
          contentTypeId,
          isBlogPostsView,
          sorts: [sortedColumn]
        });
      }
    } else {
      // Default to same filters as 'all' standard view if user does not access to saved views
      searchOptions = getSearchOptions({
        auth,
        viewId: 'all',
        contentTypeId,
        isBlogPostsView,
        sorts: [sortedColumn]
      });
    }
    if (searchOptions) {
      requestDispatcher({
        earlyRequestKey: 'fetch-content-for-view',
        url,
        type: 'POST',
        data: JSON.stringify(searchOptions)
      });
    }
  }
  if (isSitePagesView || isLandingPagesView) {
    requestDispatcher({
      earlyRequestKey: 'fetch-shared-pages-usage',
      url: 'cospages/v2/pages/usage',
      type: 'GET'
    });
  }
  if (isBlogView) {
    const initialBusinessUnit = getInitialBusinessUnitIdFilterValue(gates, scopes);
    const maybeBusinessUnitPath = initialBusinessUnit !== undefined ? `/business-unit/${initialBusinessUnit}` : '';
    requestDispatcher({
      earlyRequestKey: 'initial-blogs-fetch',
      url: `blog-settings/v1/blogs${maybeBusinessUnitPath}?${buildQueryString({
        offset: [0],
        limit: [300],
        order: ['name'],
        property: ['absoluteUrl', 'id', 'language', 'portalId', 'name', 'slug', 'updated', 'created', 'itemTemplateIsShared', 'itemLayoutId', 'itemTemplatePath', 'listingLayoutId', 'listingPageId', 'listingLayoutPath', 'listingTemplatePath', 'subscriptionListsByType', 'resolvedDomain', 'translations', 'publicAccessRules', 'publicAccessRulesEnabled'],
        translated_from_id__is_null: [' ']
      })}`,
      type: 'GET'
    });
  }
});