import quickFetch from 'quick-fetch';
import { CONTENT_OBJECT_TYPE_ID } from './requestConstants';
import { getInitialBusinessUnitIdFilterValue } from './utils';
export const NO_TRANSLATIONS_FILTER = {
  operator: 'NOT_HAS_PROPERTY',
  property: 'hs_translated_from_id'
};
export const NO_TEST_VARIANTS_FILTER = {
  operator: 'NOT_IN',
  property: 'hs_ab_status',
  values: ['mab_variant', 'variant', 'loser_variant']
};
export const NO_ARCHIVED_CONTENT_FILTER = {
  operator: 'EQ',
  property: 'hs_archived',
  value: false
};
const ContentTypeIdToContentType = {
  1: 'landing',
  4: 'site'
};
const getViewsColumnSort = property => [{
  property,
  order: 'DESC'
}];
export const getNonViewsCustomColumnSort = ({
  isBlogPostsView,
  contentTypeId
}) => {
  const portalId = quickFetch.getPortalId();
  let columns;
  try {
    columns = isBlogPostsView ? window.localStorage.getItem(`cms-control-center-blog-posts-framework-custom-columns_${portalId}`) || window.localStorage.getItem(`cms-control-center-blog-posts-custom-columns_${portalId}`) : window.localStorage.getItem(`cms-control-center-${ContentTypeIdToContentType[contentTypeId]}-pages-custom-columns_${portalId}`) || window.localStorage.getItem(`cms-control-center-${ContentTypeIdToContentType[contentTypeId]}-pages-framework-custom-columns_${portalId}`);
  } catch (e) {
    columns = '["hs_name", "hs_updated_at"]';
  }
  if (columns) {
    let customColumns = null;
    try {
      customColumns = JSON.parse(columns);
    } catch (e) {
      //noop
      return {
        property: 'hs_updated_at',
        order: 'DESC'
      };
    }
    if (customColumns) {
      const totalColumns = customColumns.length;
      if (totalColumns > 0) {
        if (customColumns.includes('updated') || customColumns.includes('hs_updated_at')) {
          return {
            property: 'hs_updated_at',
            order: 'DESC'
          };
        } else if (customColumns.includes('created') || customColumns.includes('hs_createdate')) {
          return {
            property: 'hs_createdate',
            order: 'DESC'
          };
        } else if (customColumns.includes('published') || customColumns.includes('hs_publish_date')) {
          return {
            property: 'hs_publish_date',
            order: 'DESC'
          };
        } else {
          return {
            property: 'hs_name',
            order: 'ASC'
          };
        }
      } else {
        return {
          property: 'hs_name',
          order: 'ASC'
        };
      }
    }
  }
  return {
    property: 'hs_updated_at',
    order: 'DESC'
  };
};
const getUserIdFilterValue = (auth, property) => ({
  operator: 'IN',
  property,
  values: [`${auth.user.user_id}`]
});
const getFilters = ({
  isBlogPostsView,
  viewId,
  auth
}) => {
  switch (viewId) {
    case 'created-by-me':
      {
        const userIdFilter = getUserIdFilterValue(auth, 'hs_created_by_id');
        return isBlogPostsView ? [userIdFilter, NO_ARCHIVED_CONTENT_FILTER] : [userIdFilter, NO_TEST_VARIANTS_FILTER, NO_ARCHIVED_CONTENT_FILTER];
      }
    case 'updated-by-me':
      {
        const userIdFilter = getUserIdFilterValue(auth, 'hs_updated_by_id');
        return isBlogPostsView ? [userIdFilter, NO_ARCHIVED_CONTENT_FILTER] : [userIdFilter, NO_TEST_VARIANTS_FILTER, NO_ARCHIVED_CONTENT_FILTER];
      }
    case 'recently-published':
      return isBlogPostsView ? [{
        operator: 'IN',
        property: 'hs_state',
        values: ['PUBLISHED']
      }] : [{
        operator: 'IN',
        property: 'hs_state',
        values: ['PUBLISHED', 'PUBLISHED_OR_SCHEDULED', 'PUBLISHED_AB', 'PUBLISHED_AB_VARIANT']
      }, NO_TEST_VARIANTS_FILTER, NO_ARCHIVED_CONTENT_FILTER];
    case 'all':
    case 'recently-updated':
    default:
      return isBlogPostsView ? [NO_TRANSLATIONS_FILTER, NO_ARCHIVED_CONTENT_FILTER] : [NO_TEST_VARIANTS_FILTER, NO_TRANSLATIONS_FILTER, NO_ARCHIVED_CONTENT_FILTER];
  }
};
const addBusinessUnitFilter = (filters, gates, scopes) => {
  const selectedBusinessUnitFromGlobalNav = getInitialBusinessUnitIdFilterValue(gates, scopes);
  if (selectedBusinessUnitFromGlobalNav !== undefined) {
    return [...filters, {
      operator: 'EQ',
      property: 'hs_all_assigned_business_unit_ids',
      value: parseInt(selectedBusinessUnitFromGlobalNav, 10)
    }];
  }
  return filters;
};
const UpdatedAtSort = getViewsColumnSort('hs_updated_at');
const SortValues = {
  'created-by-me': getViewsColumnSort('hs_createdate'),
  'updated-by-me': UpdatedAtSort,
  'recently-published': getViewsColumnSort('hs_publish_date'),
  all: UpdatedAtSort,
  'recently-updated': UpdatedAtSort
};
export const getSearchOptions = ({
  auth,
  viewId,
  contentTypeId,
  isBlogPostsView,
  sorts
}) => {
  return {
    filterGroups: [{
      filters: addBusinessUnitFilter(getFilters({
        isBlogPostsView,
        viewId,
        auth,
        contentTypeId
      }), auth.portal.enabled_gates, auth.user.scopes)
    }],
    sorts: sorts || SortValues[viewId],
    objectTypeId: CONTENT_OBJECT_TYPE_ID,
    count: 25,
    query: '',
    offset: 0
  };
};
export const getStartStateSearchOptions = auth => ({
  filterGroups: [{
    filters: addBusinessUnitFilter([], auth.portal.enabled_gates, auth.user.scopes)
  }],
  objectTypeId: CONTENT_OBJECT_TYPE_ID,
  sorts: UpdatedAtSort,
  count: 1,
  query: '',
  offset: 0
});