import quickFetch from 'quick-fetch';
//@ts-expect-error untyped-module
import SafeStorage from 'SafeStorage';
const getUrl = endpoint => quickFetch.getApiUrl(`/${endpoint}`, true);
export const requestDispatcher = ({
  earlyRequestKey,
  url,
  type,
  data,
  whenFinished
}) => {
  quickFetch.makeEarlyRequest(earlyRequestKey, {
    url: getUrl(url),
    timeout: 15000,
    dataType: 'json',
    contentType: 'application/json',
    type: type || 'GET',
    data,
    whenFinished
  });
};
export const buildQueryString = queryParams => {
  let queryString = '';
  Object.keys(queryParams).forEach(queryParam => {
    const queryParamJoin = `&${queryParam}=`;
    queryString += queryParamJoin + queryParams[queryParam].join(queryParamJoin);
  });
  return queryString;
};
const getSelectedBusinessUnitIdFromGlobalNav = () => {
  let selectedBusinessUnitStorageValue;
  try {
    selectedBusinessUnitStorageValue = localStorage.getItem('SELECTED_BUSINESS_UNIT');
    if (selectedBusinessUnitStorageValue) {
      selectedBusinessUnitStorageValue = JSON.parse(selectedBusinessUnitStorageValue);
    }
  } catch (e) {
    selectedBusinessUnitStorageValue = undefined;
  }
  const portalId = quickFetch.getPortalId();
  if (selectedBusinessUnitStorageValue && portalId) {
    return selectedBusinessUnitStorageValue[portalId];
  }
  return '0';
};
export const getInitialBusinessUnitIdFilterValue = (gates, scopes) => {
  if (gates.includes('navigation:businessUnitPicker') && scopes.includes('business-units-access')) {
    const selectedBusinessUnitFromGlobalNav = getSelectedBusinessUnitIdFromGlobalNav();
    if (selectedBusinessUnitFromGlobalNav !== undefined && selectedBusinessUnitFromGlobalNav !== 'ALL') {
      return selectedBusinessUnitFromGlobalNav;
    }
  }
  return undefined;
};
export const getMaybeCachedFirstViewRequestData = (contentTypeId, portalId) => {
  try {
    const value = SafeStorage.getItem(`cms-control-center:${portalId}:${contentTypeId}:first-view`);
    return value ? JSON.parse(value) : undefined;
  } catch (e) {
    return undefined;
  }
};